@import '~antd/dist/antd.css';

.Login-layout-main{
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    min-height: 600px;
}
.Login-layout-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 0 80px 0 ;
}

.Login-logo {
    margin: 60px 0;
    font-size: 22px;
    font-weight: bold;
}
